/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

const SecondFaqSection = ({ data, positions }) => {
	return (
		<section className='max-w-7xl mx-auto sm:px-6 lg:px-8 mt-5'>
			<div
				style={{ backgroundImage: `url('https://firebasestorage.googleapis.com/v0/b/cms-server-69a05.appspot.com/o/about%2Fadagio-60.jpg?alt=media&token=a04ac88b-db37-4943-92b9-70002b99c2f2')` }}
				className='w-full flex justify-center py-10 px-12 sm:px-20 md:px-28 lg:px-36'
			>
				<div className='bg-white p-6 shadow-lg w-full'>
                    <div style={{ textAlign: 'right' }}>
                        <button 
                        style={{ fontSize: '13px', backgroundColor: '#0173b9', color: 'white', padding: '5px 10px 5px 10px' }}
                        onClick={() => window.location.href = '/register'}
                        >
                        Sign Up for Investor Information
                        </button>
                    </div>
                    {data && data.length > 0 ? (
                        data.map((item, index) => (
                            <div key={index} className='mb-4'>
                                <h3 className='text-xl font-semibold mb-2'>{item.question}</h3>
                                <p className='text-gray-700 ql-editor'>{item.answer}</p>
                            </div>
                        ))
                    ) : (
                        <p className='text-gray-700'>No FAQs available.</p>
                    )}
                </div>
			</div>
		</section>
	);
};

export default SecondFaqSection;
